<template>
  <div class="flex-col" style="height: 100%">
    <div class="flex-col items-center di" style="flex:1;padding: 3.25rem 1rem 0;overflow: auto">
      <p style="font-size: 1rem;margin: 1.6875rem 0 3rem;align-self: center">麦嘉金融</p>
      <span style="font-size: 0.875rem;line-height: 1.3125rem">
         前海博燊保理（深圳）有限公司，成立于2014年，是一家集保付代理; 投资管理;供应链管理为一体的企业。
         前海博燊保理拥有严谨、完善的管理机构及高素质的管理人员，有完整、规范的风控体系，致力于在互联网时代帮助中国企业进行营销方式的革命，并使企业在这场营销变革中抢占先机。
       </span>
      <span style="font-size: 0.875rem;line-height: 1.3125rem;margin-top: 1rem">
        前海博燊保理定位于以核心企业的应付账款和中小企业应收账款为核心标的，围绕跨境电商商家的应收应付账款开发跨境商家保理业务，同时研发线上化商业保理系统。
      </span>
      <img
          style="width: 20.5rem;margin-top: 2.875rem"
          :src="require('@/assets/aboutUs.png')"
          alt="关于我们"
      />
    </div>
    <div style="background: #A1ABD6;padding: 0.875rem 1.5rem">
      <div style="display: flex">
        <div class="flex-col" style="width: 70%">
          <span style="font-size: 0.75rem">为跨境企业提供全方位、高效、安全的跨境金融服务</span>
          <el-button class="about_apply" @click="$router.push({path:'/home/apply'})">我要申请</el-button>
        </div>
        <img
            class="shrink-0 image_18"
            :src="require('@/assets/QR_code.png')"
        />
      </div>
        <div style="font-size:0.625rem;color:#666;margin-top: 1rem">版权所有@麦嘉Maka<a class="aq" target="_blank" href="https://beian.miit.gov.cn">粤ICP备2023119718号-1</a></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped>
.aq{
  margin-left: 1rem;
  color: #666;
  text-decoration: none;
}
.aq:hover{
  text-decoration: underline;
}
.about_apply{
  color: #161616;
  line-height: 2.375;
  font-size: 0.875rem;
  width:10.5rem;
  margin-top: 1.625rem;
  border:0;
  border-radius: 0.25rem;
  background-image: linear-gradient(90deg, #ffbe58 0%, #ff863d 100%);
}
</style>